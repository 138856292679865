import types from '../types';

const initialState = {
  jwtAccessToken: '',
  auditDetails: {},
};

const auditDetails = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState
          };
      case types.SET_AUDIT_DATA:
        const { auditDetail} = action.payload;
        return {
          ...state,
          auditDetail,
        };
    default:
      return state;
  }
};


export default auditDetails;