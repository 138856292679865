import types from '../types';

const initialState = {
  jwtAccessToken: '',
  workDetail: false,
};

const workDetail = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case "RESET_WORKDETAIL_DATA":
        return { 
           ...initialState ,
          };
      case types.SET_WORKDETAIL_DATA:
        const { workDetail} = action.payload;
        return {
          ...state,
          workDetail,
        };
    default:
      return state;
  }
};

export default workDetail;
