import types from '../types';

const initialState = {
    jwtAccessToken: '',
    module: "",
};

const modules = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_JWT:
            return {
                ...state,
                jwtAccessToken: action.payload,
            };
        case types.RESET_CONFIGURATOR_STATE:
            return {
                ...initialState,
            };
        case types.SET_MODULE_DATA:
            const { module } = action.payload;
            return {
                ...state,
                module,
            };
        default:
            return state;
    }
};

export default modules;