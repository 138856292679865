import types from '../types';

const initialState = {
  jwtAccessToken: '',
  getIdlsMachineDetails: false,
};

const getIdlsMachineDetails = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState ,
          };
      case types.GET_IDLS_MACHINE_DETAILS:
        const {  getIdlsMachineDetails} = action.payload;
        return {
          ...state,
          getIdlsMachineDetails,
        };
    default:
      return state;
  }
};

export default getIdlsMachineDetails;
