import React, { useEffect, useState } from "react";
// import './index.scss';
import { useSelector } from "react-redux";
import "../Common-style.scss";

const Footer = (props) => {
  const userProfile = useSelector((state) => state.user.userProfile);
  const [unauthorized, setUnauthorized] = useState("");
  useEffect(() => {
    if (userProfile && userProfile.msg === "Unauthorized User") {
      setUnauthorized(true);
    } else if (userProfile && userProfile.msg === "Authorized User") {
      setUnauthorized(false);
    }
  }, [userProfile]);

  return (
    <>
      {unauthorized ? null : unauthorized === false ? (
        <div className="app-footer">
          <div className="containt-wrapper">
            <div className="row margin-align">
              {/* <colomn 1> */}
              <div className="part1">
                <div className="row">
                  <div className="col">

                    <a
                      href="https://www.nsws.gov.in/website-policies#tabs-1"
                      target="_blank"
                    >
                      {" "}
                      Privacy Policy{" "}
                    </a>
                    {/* <Link to={`/Terms`} target="_blank">Terms of Use </Link> */}
                    {/* &nbsp;&nbsp; ∘ &nbsp;&nbsp; */}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      href="https://www.nsws.gov.in/website-policies#tabs-3"
                      target="_blank"
                    >
                      Terms of Use
                    </a>



                    {/* <Link to={`/Policy`} target="_blank"> Privacy Policy </Link> */}

                    {/* &nbsp;&nbsp; ∘ &nbsp;&nbsp;
                    
                     <a
                      href="https://ppe-nsws.maadhyam.gov.in/contact-us"
                      target="_blank"
                    >
                      {" "}
                      Contact Us{" "}
                    </a> */}

                    {/* <Link to={`/contact`} target="_blank"> Contact Us </Link> */}

                    {/* change here */}
                    &nbsp;&nbsp;&nbsp;&nbsp;

                    <p1 className="copyright" href="">
                      &copy; Copyright @ 2021 Invest India.
                    </p1>

                  </div>
                </div>
                {/* <div className="row">
                  <p className="col-sm">
                    &copy; Copyright @ 2021 Invest India.
                  </p>
                </div> */}
              </div>

              {/* <colomn 2> */}
              {/* <div className="part2">
                                     
                   <div className="col">
                     
                        </div>
                        
                   </div> */}
              {/* {window.location.pathname !== "/login" 
                    && window.location.pathname !== "/logout" && window.location.pathname !== "/Policy" 
                    && window.location.pathname !== "/contact"
                    && window.location.pathname !== "/FAQ"  ? <MessageBox /> : null } */}

              {/* { window.location.pathname === "/workitem" ?  <MessageBox  /> : null } */}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Footer;
