import types from '../types';

const initialState = {
  jwtAccessToken: '',
  reassignWorkList: false,
};

const reassignWorkList = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState
          };
      case types.SET_REASSIGN_WORKLIST:
        const { reassignWorkList} = action.payload;
        return {
          ...state,
          reassignWorkList,
        };
    default:
      return state;
  }
};

export default reassignWorkList;