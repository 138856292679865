import types from '../types';

const initialState = {
  jwtAccessToken: '',
  getScrutinyRemark: false,
};

const getScrutinyRemark = (state = initialState, action) => {

  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState
          };
      case types.GET_SCRUTINY_REMARK:
       
        const { getScrutinyRemark} = action.payload;
        return {
          ...state,
          getScrutinyRemark,
        };
    default:
      return state;
  }
};


export default getScrutinyRemark;