import types from '../types';

const initialState = {
  jwtAccessToken: '',
  licensePDF: false,
};

const licensePDF = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState ,
          };
      case types.GENERATE_LICENSE_PDF:
        const { licensePDF} = action.payload;
        return {
          ...state,
          licensePDF,
        };
    default:
      return state;
  }
};

export default licensePDF;
