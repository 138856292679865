import types from "../types";

export const apiStart = (label) => ({
  type: types.API_START,
  payload: label,
});

export const apiEnd = (label) => ({
  type: types.API_END,
  payload: label,
});

export const accessDenied = (url) => ({
  type: types.ACCESS_DENIED,
  payload: {
    url,
  },
});

export const apiError = (
  error,
  { url, method, headers, data, isBackgroundCall }
) => {
  if (error && error === "Network Error") {
    return {
      type: types.SHOW_ERROR_MODAL,
      payload: {
        error: isBackgroundCall ? "" : 512,
      },
    };
  }
  return {
    type: types.SHOW_ERROR_MODAL,
    payload: {
      error: isBackgroundCall ? "" : 500,
    },
  };
};

export const apiErrorLogout = (
  //error,
  //{ url, method, headers, data, isBackgroundCall }
) => {
  document.cookie = "isLoggedIn=1; expires=Thu, 01 Jan 1970 00:00:00 GMT";
};

export function fetchData({
  url = "",
  method = "post",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  headersOverride = null,
  isBackgroundCall = false,
  addJwtFromRedux = false,
  addUserName = false,
  addSrutiny = false,
  paymentApiCall= false,
  headers,
  removeappidHeader = false,
  responseType = false,
}) {
  //console.log("URL "+url);
  //console.log("accessTOken inside fetch data **** "+accessToken);
  //console.log("onsuccess "+onSuccess);
  return {
    type: types.API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride,
      isBackgroundCall,
      addJwtFromRedux,
      addUserName,
      addSrutiny,
      paymentApiCall,
      headers,
      removeappidHeader,
      responseType,
    },
  };
}

export function fetchGetData({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  headersOverride = null,
  isBackgroundCall = false,
  addJwtFromRedux = false,
  addUserName = false,
  addSrutiny = false,
  paymentApiCall= false,
  headers,
  removeappidHeader = false,
  responseType = false,
}) {
  //console.log("URL "+url);
  //console.log("accessTOken inside fetch data **** "+accessToken);
  //console.log("onsuccess "+onSuccess);
  return {
    type: types.API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride,
      isBackgroundCall,
      addJwtFromRedux,
      addUserName,
      addSrutiny,
      paymentApiCall,
      headers,
      removeappidHeader,
      responseType,
    },
  };
}