import types from "../types";

const initialState = {
  jwtAccessToken: "",
  filterData: {
    dateData: {
      startDate: "",
      endDate: "",
    },
    licenseDesc: [],
    swsIdData: [],
    appIdData: [],
    statusData:[],
    userList:[],
    deptData:[],
    recordsPerPage: 10,
    firstRecFiltrCount: 0,
    lastRecFiltrCount: 0,
    currenPage: 1,
    nexPage: 1,
    appliCountRedux: 0,
    workListCountRedux: 0,
  },
};

const compilienceFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COMPILICE_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
    case types.RESET_COMPILICE_CONFIGURATOR_STATE:
      return {
        ...initialState,
      };
    case types.SET_COMPLICE_FILTER_DATA:
      const { filterData } = action.payload;
      return {
        ...state,
        filterData,
      };
    case types.RESET_Compilance_FILTER_DATA:
      return {
        ...initialState,
      };
    case "RESET_COMPILIENCE_CHECK_FILTER_DATA":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          appIdData: [],
          swsIdData: [],
          licenseDesc: "",
          dateData: {
            startDate: "",
            endDate: "",
          },
        },
      };
    default:
      return state;
  }
};

export default compilienceFilterReducer;
