import types from '../types';

const initialState = {
    jwtAccessToken: '',
    getDashboardReportDataRedux: false,
};

const getDashboardRepData = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_JWT:
            return {
                ...state,
                jwtAccessToken: action.payload,
            };
        case types.RESET_CONFIGURATOR_STATE:
            return {
                ...initialState,
            };
        case types.SET_DASHBOARD_REPORT_DATA:
            const { getDashboardReportDataRedux } = action.payload;
            return {
                ...state,
                getDashboardReportDataRedux,
            };
        default:
            return state;
    }
};

export default getDashboardRepData;
