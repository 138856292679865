import types from '../types';

const initialState = {
    jwtAccessToken: '',
    licenseSubFields: false,
};

const licenseSubFields = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_JWT:
            return {
                ...state,
                jwtAccessToken: action.payload,
            };
        case types.RESET_CONFIGURATOR_STATE:
            return {
                ...initialState,
            };
        case types.SET_LICENSE_SUBFIELDS_DATA:
            const { licenseSubFields } = action.payload;
            return {
                ...state,
                licenseSubFields,
            };
        default:
            return state;
    }
};

export default licenseSubFields;
