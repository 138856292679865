import types from '../types';

const initialState = {
  jwtAccessToken: '',
  pushScrutinyRemark: false,
};

const pushScrutinyRemark = (state = initialState, action) => {

  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState
          };
      case types.PUSH_SCRUTINY_REMARK:       
        const { pushScrutinyRemark} = action.payload;
        return {
          ...state,
          pushScrutinyRemark,
        };
    default:
      return state;
  }
};


export default pushScrutinyRemark;