import types from '../types';

const initialState = {
  jwtAccessToken: '',
  compenseItem: [],
};

const ComplienceworkItems = (state = initialState, action) => {
  switch (action.type) {
      case types.SET_COMPLIENCE_WORKITEM_DATA:
        const { compenseItem} = action.payload;
        return {
          ...state,
          compenseItem,
        };
    default:
      return state;
  }
};

export default ComplienceworkItems;
