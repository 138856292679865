import types from "../types";

const initialState = {
  compenseList: false,
  compenseItem: [],
  appIdComList:[],
  swsIdComList:[],
  orgIdComList:[]
  
};

const compilienceworklist = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COMPENCEWORKLIST_DATA:
      const { compenseList } = action.payload;
      return {
        ...state,
        compenseList,
      };
    case types.RESET_COMPENSEWORKLIST_DATA:
      return {
        ...state,
        workLists: false,
      };
      case types.SET_ORGID_COMP_LIST_DATA: {
        const { orgIdComList} = action.payload;
        return {
          ...state,
          orgIdComList,
        };
      }
      case types.SET_APPID_COMP_LIST_DATA:{
        const { appIdComList} = action.payload;
        return {
          ...state,
          appIdComList,
        };
      }
      case types.SET_SWSID_COMP_LIST_DATA:{
        const { swsIdComList} = action.payload;
        return {
          ...state,
          swsIdComList,
        };
      }

    default:
      return state;
  }
};
export default compilienceworklist;
