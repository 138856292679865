import types from '../types';

const initialState = {
  jwtAccessToken: '',
  workItems: [],
};

const workItems = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case "RESET_WORKITEM_DATA":
      return { 
         ...initialState,
        };
      case types.SET_WORKITEM_DATA:
        const { workItems} = action.payload;
        return {
          ...state,
          workItems,
        };
    default:
      return state;
  }
};

export default workItems;
