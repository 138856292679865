import types from '../types';

const initialState = {
  jwtAccessToken: '',
  approvalDocFlag: '',
};

const approvalDocFlag = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState ,
          };
      case types.APPROVAL_DOC_FLAG:
        const { approvalDocFlag} = action.payload;
        return {
          ...state,
          approvalDocFlag,
        };
    default:
      return state;
  }
};

export default approvalDocFlag;
