import types from '../types';

const initialState = {
    jwtAccessToken: '',
    licenseFields: false,
};

const licenseFields = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_JWT:
            return {
                ...state,
                jwtAccessToken: action.payload,
            };
        case types.RESET_CONFIGURATOR_STATE:
            return {
                ...initialState,
            };
        case types.SET_LICENSE_FIELDS_DATA:
            const { licenseFields } = action.payload;
            return {
                ...state,
                licenseFields,
            };
        default:
            return state;
    }
};

export default licenseFields;
