import types from '../types';

const initialState = {
  jwtAccessToken: '',
  dscPdf:{}
};

const dscPdf = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState ,
          };

          case types.SET_DSC_PDF:
          const {dscPdf} = action.payload  
          return{
              ...state,
              dscPdf
            }

    default:
      return state;
  }
};

export default dscPdf;
