import React, { useState } from 'react';
import FilterDeptContext from "../contextFiles/filterContext";


const ContextContainer =(props)=>{
    const [licenseShortDesc, setLicenseShortDesc]= useState("All Approvals");
    const [finalWorklistData, setFinalWorklistData]=useState(false);
    const [unfilteredWorklistData, setUnfilteredWorklistData]= useState(false);
    const [statusFilter, setStatusFilter] = useState({
    "INPROCESS": false,
    "QUERY RAISED": false,
    "QUERY RESOLVED":false,
    "Approved": false,
    "Rejected": false,
    "Pending": false,
    "New Request":false,
    "All":false,
      });

  //  const [pending, setPending]= useState(false);
    // const [approved, setApproved]
    return (
        <FilterDeptContext.Provider value={{licenseShortDesc,setLicenseShortDesc,finalWorklistData,setFinalWorklistData,unfilteredWorklistData,setUnfilteredWorklistData, statusFilter, setStatusFilter}}>
            {props.children}
        </FilterDeptContext.Provider>
    )
}
export default ContextContainer;