import types from '../types';

const initialState = {
    jwtAccessToken: '',
    editWorkflow: false,
};

const editWorkflow = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_JWT:
            return {
                ...state,
                jwtAccessToken: action.payload,
            };
        case types.RESET_CONFIGURATOR_STATE:
            return {
                ...initialState,
            };
        case types.SET_EDIT_WORKFLOW:
            const { editWorkflow } = action.payload;
            return {
                ...state,
                editWorkflow,
            };
        default:
            return state;
    }
};

export default editWorkflow;