import types from '../types';

const initialState = {
    jwtAccessToken: '',
    licenseForms: false,
};

const licenseForms = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_JWT:
            return {
                ...state,
                jwtAccessToken: action.payload,
            };
        case types.RESET_CONFIGURATOR_STATE:
            return {
                ...initialState,
            };
        case types.SET_LICENSE_FORMS_DATA:
            const { licenseForms } = action.payload;
            return {
                ...state,
                licenseForms,
            };
        default:
            return state;
    }
};

export default licenseForms;
