import types from '../types';

const initialState = {
  jwtAccessToken: '',
  dataSign:{}
};

const dataSign = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState ,
          };

          case types.SET_DATA_SIGN:
          const {dataSign} = action.payload  
          return{
              ...state,
              dataSign
            }

    default:
      return state;
  }
};

export default dataSign;
