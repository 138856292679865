import React, { useState, useEffect } from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import CryptoJS from 'crypto-js';

//import { LoginAction } from "../../redux/action/Login";
import { ForgotpasswordAction } from "../../redux/action/ForgotPassword";
import Header from "../Header";
import Footer from "../Footer";
import forgotimg from "../../assets/images/ForgotPassword.svg";
import refresh from "../../assets/images/refresh.svg";
import LoginContent from "./LoginContent";

import "../Common-style.scss";
import { Button, Form, Row, Col, Container } from 'react-bootstrap'
import { getCaptchaCode } from '../../redux/action/CaptchaCode';
import { validateCaptchaCode } from '../../redux/action/ValidateCaptcha';
import { refreshCaptchaCode } from '../../redux/action/RefreshCaptcha';
import UserService from "../../redux/services/UserService"

import { FaSyncAlt } from 'react-icons/fa';

const LoginPage = (props) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [captchaId, setCaptchaId] = useState("");
  const [image, setImage] = useState(false);
  const [commonErr, setCommonErr] = useState("");
  const [showHome, setShowHome] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showFP, setShowFP] = useState(false);
  const [showFPV, setShowFPV] = useState(false);
  // if(!UserService.isLoggedIn())
  //           UserService.doLogin();
  //       else {
  //           //verifyUserRole();
  //       }
  const userProfile = useSelector((state) => state.user.userProfile);

  const userNameRegex = /^([a-zA-Z]){3}([0-9]){4}?$/;
  //const userNameRegex2=/^([a-zA-Z]){3}([0-9]){3}?$/;

  useEffect(() => {
    localStorage.clear()
  }, [])

  const loginSet = () => {
    setShowHome(true);
    setShowLogin(false);
  };

  const captchaRefresh = (bool) => {
    captchaId && dispatch(refreshCaptchaCode(captchaId, onSuccessCaptcha));
    if (bool) {
      document.getElementById("captcha").value = "";
    }

  }

  // const onLoginError = () => {
  //   // alert("Please try to login again");
  //   // window.location.pathname="/login";
  // };

  const onFPError = () => {
    alert("Please try to login again");
    window.location.reload();
  };

  const onFPSuccess = (data) => {
    console.log("success::" + data.message);
    if (data.message === "SUCCESS") {
      forgotpasswordSet();
      setCommonErr("");
    }
    else {
      setCommonErr(data.response);
      captchaRefresh(true);
    }
  };

  const forgotpasswordSet = () => {
    setShowFPV(false);
    setShowFP(true);
    setShowLogin(false);
  };

  const onLoginSuccess = (data) => {
    console.log("success::" + data.errorCode);

    if (data.errorCode === null && data.userProfileSubSet !== null) {
      setCommonErr("");
      loginSet();
    } else if (data.errorCode === null && data.userProfileSubSet === null) {
      setCommonErr("Currently, We are facing some technical problem. Please, try again later.");
      captchaRefresh(true);
    } else {
      setCommonErr(data.errorCode);
      captchaRefresh(true);
    }
  };

  const handleFPClick = () => {
    setUsername("");
    setCommonErr("");
    setShowFPV(true);
    setShowHome(false);
    setShowHome(false);
    setShowLogin(false);
  };

  const handleBLClick = () => {
    setCommonErr("");
    setShowFPV(false);
    setShowFP(false);
    setShowHome(false);
    setShowLogin(true);
    captchaRefresh(false);
  };

  const handleFPCClick = () => {
    console.log("inside", username);

    if (username === "") {
      console.log("Please enter the username", username);
      setCommonErr("Please enter the username");
      captchaRefresh(true);
    }
    else if (captchaAnswer === "") {
      setCommonErr("Please enter the captcha");
      captchaRefresh(true);
    } else {
      setCommonErr("");
    }
    username && captchaId && captchaAnswer && dispatch(ForgotpasswordAction(username, captchaId, captchaAnswer, onFPSuccess, onFPError, forgotpasswordSet));
  };

  useEffect(() => {
    //  dispatch(getCaptchaCode(onSuccessCaptcha));
    UserService.doLogin();
    console.log("dispatch captcha done")
    if (!UserService.isLoggedIn()) {
      UserService.doLogin();
      console.log("logged in after doLogin method");
    }
    else {
      UserService.verifyUserRole();
      console.log("logged in after verifyUserRole method");
    }
  }, []);

  const onSuccessCaptcha = (captchaData) => {
    //  console.log("captcha:::::"+JSON.stringify(captchaData.captchaImg));
    // console.log("captcha:::::"+JSON.stringify(captchaData.captchaId));
    setImage(captchaData.captchaImg);
    setCaptchaId(captchaData.captchaId);
  }

  // const handleSubmit = () => {
  //   console.log("inside", username);
  //   console.log("inside1", password);

  //   if (username === "") {
  //     console.log("Please enter the username", username);
  //     setCommonErr("Please enter the username.");
  //     captchaRefresh();
  //   } else if (password === "") {
  //     setCommonErr("Please enter the password.");
  //     captchaRefresh();
  //   } else if (captchaAnswer === "") {
  //     setCommonErr("Please enter the captcha.");
  //     captchaRefresh();
  //   } else {
  //     setCommonErr("");
  //   }
  //   username && password && captchaId && captchaAnswer && dispatch(LoginAction(username, password, captchaId, captchaAnswer, onLoginSuccess, onLoginError));
  // };
  // const onKeyUp = (event) => {
  //   if (event.key === 'Enter') {
  //     console.log('enter press here! ');
  //     handleSubmit()
  //   }
  // }

  const handleUserName = (event) => {
    setUsername(event.target.value);
    const name = event.target.value === "" ? username : event.target.value;
    if (name.length > 6) {
      if (userNameRegex.test(name)) {
        setCommonErr("")
      }
      else {
        setCommonErr("Invalid User ID.");
      }
    }
    else {
      setCommonErr("")
    }
  }

  return (
    <div>
      {/* {showLogin === true ? (
        <div>
          <Header />
          <MuiThemeProvider>
            <div className="login-image">
              <LoginContent />
            </div>
          </MuiThemeProvider>

          <MuiThemeProvider>
            <div className="login-page">
              <h3 className="login-header">Login</h3>
              <MuiThemeProvider key={"theme"}>
                <span
                  style={{
                    color: "red",
                    paddingLeft: "50px",
                    fontFamily: "Roboto condensed ",
                  }}
                >
                  {commonErr}
                 </span>
                <div className="aline-center">
                  <div>
                    <TextField
                      name="username"
                      floatingLabelText="User Name"
                      style={styleTxt}
                      className="textFeild-align "
                      onChange={(e) => handleUserName(e)}
                      maxlength="7"
                      autoComplete="off"
                       />
                    <TextField
                      name="password"
                      type="password"
                      floatingLabelText="Password"
                      style={styleTxt}
                      className="textFeild-align"
                      onChange={(e) => setPassword(e.target.value)}
                      maxlength="20"
                      autoComplete="off"
                    />
                    <br />
                    <div className="about-position textFeild-size textFeild-align">

                      <img src={`data:image/jpeg;base64,${image}`} alt="captcha" className="captcha-padding" />
                      <img src={refresh} alt="Recaptcha" onClick={()=>captchaRefresh(true)} className="captcha-padding recaptcha Pointericon" />
                    </div>

                    <TextField
                      name="captcha"
                      type="captcha"
                      id="captcha"
                      floatingLabelText="Captcha"
                      style={styleTxt}
                      className="textFeild-align"
                      onChange={(e) => setCaptchaAnswer(e.target.value)}
                      onKeyPress={(e) => onKeyUp(e)}
                      maxlength="10"
                      autoComplete="off"
                    />
                  </div>
                  <RaisedButton
                    label="Submit"
                    primary={true}
                    style={style}
                    className="font-family"
                    onKeyPress={(e) => onKeyUp(e)}
                    onClick={() => handleSubmit()}
                  />
                  <button type="button" class="link-btn pl0" onClick={() => handleFPClick()}>
                    <span class="button-text" >
                      Forgot your password?</span></button>
                </div>
              </MuiThemeProvider>
            </div>
          </MuiThemeProvider>
          <div className="footer-align">
            <Footer />
          </div>
        </div>
      ) : null}

      {showFPV === true ? (
        <div>
          <Header />
          <MuiThemeProvider>
            <div className="login-image">
              <LoginContent />
            </div>
          </MuiThemeProvider>

          <MuiThemeProvider>
            <div className="login-page">
              <h3 className="login-header">Forgot Password</h3>

              <br />
              <label class="FPText">Enter your username, and we'll help you set up a new password.</label>

              <MuiThemeProvider key={"theme"}>
                <span
                  style={{
                    color: "red",
                    paddingLeft: "200px",
                    fontFamily: "Roboto condensed",
                  }}
                >
                  {commonErr}
                </span>

                <div className="aline-center">
                  <div>
                    <TextField
                      name="username"
                      floatingLabelText="User Name"
                      style={styleTxt}
                      className="textFeild-align "
                      onChange={(e) => setUsername(e.target.value)}
                      maxlength="7"
                      autoComplete="off"
                    />

                    <br />
                    <div className="about-position textFeild-size textFeild-align">

                      <img src={`data:image/jpeg;base64,${image}`} alt="captcha" className="captcha-padding" />
                      <img src={refresh} alt="Recaptcha" onClick={()=>captchaRefresh(true)} className="captcha-padding recaptcha" />
                    </div>

                    <TextField
                      name="captcha"
                      type="captcha"
                      id="captcha"
                      floatingLabelText="Captcha"
                      style={styleTxt}
                      className="textFeild-align"
                      onChange={(e) => setCaptchaAnswer(e.target.value)}
                      autoComplete="off"
                      maxlength="10"
                    />
                  </div>
                  <RaisedButton
                    label="Submit"
                    primary={true}
                    style={style}
                    onClick={() => handleFPCClick()}
                  />
                  <RaisedButton
                    label="Back to Login"
                    primary={true}
                    onClick={() => handleBLClick()}
                  />

                </div>
              </MuiThemeProvider>
            </div>
          </MuiThemeProvider>
          <div className="footer-align">
            <Footer />
          </div>
        </div>
      ) : null}


      {showFP === true ? (
        <div>
          <Header />
          <MuiThemeProvider>
            <div className="login-image">
              <LoginContent />
            </div>
          </MuiThemeProvider>

          <MuiThemeProvider>
            <div className="login-page1">
              <h3 className="login-header">Forgot Password</h3>
              <img style={{ paddingLeft: '230px' }} src={forgotimg} alt="ForgotPassword" />&nbsp;&nbsp;
                &nbsp;<br></br>
              <label class="FPTextVal">Your password request has been sent successfully</label>

              <label class="FPText">We have sent a password reset link on your registered email address. Please access the mail in your inbox and click on the link to set a new password.</label>

              <MuiThemeProvider key={"theme"}>
                <span
                  style={{
                    color: "red",
                    paddingLeft: "200px",
                    fontFamily: "Roboto condensed",
                  }}
                >
                  {commonErr}
                </span>

                <div className="aline-center">
                  <RaisedButton
                    label="Back to Login"
                    primary={true}
                    onClick={() => handleBLClick()}
                  />

                </div>
              </MuiThemeProvider>
            </div>
          </MuiThemeProvider>
          <div className="footer-align">
            <Footer />
          </div>
        </div>
      ) : null}

       {showHome === true ? (
         <Route path="/" children={<Redirect to="/dashboard" />} />
       ) : null} */}
    </div>
  );
};

const style = {
  margin: 30,
  align: "center",
  width: "100px",
};
let styleTxt;
if (window.matchMedia("(max-width: 480px)").matches) {
  styleTxt = {
    margin: 10,
    backgroundColor: "white",
    fontFamily:
      "BlinkMacSystemFont, Segoe UI, Roboto condensed,Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    fontSize: 17,
    fontWeight: 400,
    paddingTop: "20px",
    paddingBottom: "19px",
    width: "200px",
    height: "90px",
    // lineHeight: "1rem"
  };
} else {
  styleTxt = {
    margin: 10,
    backgroundColor: "white",
    fontFamily:
      "BlinkMacSystemFont, Segoe UI, Roboto condensed,Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    fontSize: 17,
    fontWeight: 400,
    paddingTop: "20px",
    paddingBottom: "19px",
    width: "400px",
    height: "110px",
    // lineHeight: "1rem"
  };
}
const Login = withRouter(LoginPage);
export default Login;