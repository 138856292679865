import types from '../types';

const initialState = {
  jwtAccessToken: '',
  deptidData: false,
};

const deptidData = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState ,
          };
      case types.SET_DEPTID_DATA:
        const { deptidData} = action.payload;
        return {
          ...state,
          deptidData,
        };
    default:
      return state;
  }
};

export default deptidData;
