import React from 'react';
import logo from '../../logo.svg';
import { withRouter } from 'react-router-dom';
// import './index.scss';
import "../Common-style.scss";

function ErrorPage(props) {/*
  return (
    <div className="not-found">
      <img className="loader-top-img" src={logo} alt="Project" />
    </div>
  );*/
  return (<div></div>);
}

export default withRouter(ErrorPage);
