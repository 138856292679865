import React from 'react';
import './loader.scss';
import LoaderImage from '../../assets/images/loader.gif'

const Loader = () => {
    return (
        <div id="loader-wrapper">
            <img src={LoaderImage} alt="loader-img" />
        </div>
    )
};

export default Loader;