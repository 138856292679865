import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import WorkList from "./reducers/workListReducer";
import WorkItem from "./reducers/workItemReducer";
import EditUser from "./reducers/editUserReducer";
import Common from "./reducers/commonReducer";
import middlewares from "./middlewares";
import AppCount from "./reducers/dashboardReducer";
import AppLicenseCount from "./reducers/appLicenseCount";
import GetUserdata from "./reducers/getUsersReducer";
import ChartCount from "./reducers/chartReducer";
import User from "./reducers/loginReducer";
import Approve from "./reducers/approveReducer";
import GetDashboardRepData from "./reducers/getDashboardReportDataReducer"
import Reject from "./reducers/rejectReducer";
//import Comment from './reducers/commentReducer';
import MinsIdData from "./reducers/minsIdReducer";
import PdfData from "./reducers/pdfReducer";
import Forward from "./reducers/forwardReducer";
import Backward from "./reducers/backwardReducer";
import DeptidData from "./reducers/deptReducer";
import LicenseidData from "./reducers/licenseIdReducer";
import LicenseForms from "./reducers/licenseFormsReducer";
import LicenseSections from "./reducers/licenseSectionsReducer";
import LicenseFields from "./reducers/licenseFieldsReducer";
import LicenseSubFields from "./reducers/licenseSubFieldsReducer";
import DeptDtlsData from "./reducers/deptDtlsReducer";
import UpdateNsws from "./reducers/updateReducer";
import UserCreate from "./reducers/createUserReducer";
import WorkflowCreate from "./reducers/createWorkflowReducer";
import DepmtIdData from "./reducers/depmtIdReducer";
import ApprovalsRequest from "./reducers/approvalsRequest";
import NoAction from "./reducers/noAction";
import StatusReport from "./reducers/statusReport";
import GenOtpData from "./reducers/genOtpReducer";
import ValidOtpData from "./reducers/validOtpReducer";
import LicenseCount from "./reducers/licenseCountReducer";
import Clarification from "./reducers/GetClarfctnReducer";
import WorkDetail from "./reducers/workDetailReducer";
import WorkListItem from "./reducers/workListItemReducer";
import WorkItemForm from "./reducers/workItemFormReducer";
import ExportExcelData from "./reducers/exportExcelReducer";
import FileDownload from "./reducers/fileDownloadReducer";
import Logout from "./reducers/logoutReducer";
import BulkFileDownload from "./reducers/bulkFileDownloadReducer";
import GetNextOfficerName from "./reducers/getNextOfficerNameReducer";
import {
  filterData,
  // ,filterUserData,filterDeptData,filterSwsIdData, filterAppIdData
} from "./reducers/filterReducer";
import ModulesRoleId from "./reducers/modulesRoleId";
import Modules from "./reducers/modules";
import ForgotPassword from "./reducers/forgotPasswordReducer";
import Licenses from "./reducers/licensesReducer";
import LicensesList from "./reducers/licensesListReducer";
import AllUsers from "./reducers/allUsersReducer";
import AllDepartments from "./reducers/allDepartmentReducer";
import Captcha from "./reducers/captchaCodeReducer";
import ValidateCaptcha from "./reducers/validateCaptchaReducer";
import RefreshCaptcha from "./reducers/refreshCaptchaReducer";
import DeptWingDtlsData from "./reducers/DeptWingDtlsReducer";
import RoleList from "./reducers/roleListReducer";
import MinistryList from "./reducers/ministryListReducer";
import UsersList from "./reducers/userListReducer";
import PaymentDetail from "./reducers/paymentDetailReducer";
import BulkFileIds from "./reducers/bulkFileIdsReducer";
import SubmitInfo from "./reducers/submitInfoReducer";
import SeekInfo from "./reducers/seekInfoReducer";
import ClaimApplication from "./reducers/claimApplicationReducer";
import ReAssignApplication from "./reducers/reAssignApplicationReducer";
import ViewWorkflow from "./reducers/viewWorkflowReducer";
import EditWorkFlow from "./reducers/editWorkflowReducer";
import VerificationSchedule from "./reducers/verificationScheduleReducer";
import WorkflowUserList from "./reducers/workflowUserListReducer";
//import MinistryLogo from './reducers/ministryLogoReducer';
import NotificationDetails from "./reducers/notificationReducer";
import NotificationFlag from "./reducers/updateNotificationReducer";
import PullLicenseDetails from "./reducers/pullLicenseDetailsReducer";
import SaveLicenseDetails from "./reducers/pullLicenseDetailsReducer";
import LicensePDF from "./reducers/licensePDFReducer";
import DscToken from "./reducers/DscTokenReducer";
import DscCertificate from "./reducers/DscCertificateReducer";
import DscPdf from "./reducers/DscPdfReducer";
import ChecklistSubmmit from "./reducers/ChecklistSubmitReducer";
import ChecklistView from "./reducers/ChecklistViewReducer";
import Designation from "./reducers/designationListReducer";
import NoteSheet from "./reducers/noteSheetReducer";
import PendingTask from "./reducers/pendingTaskReducer";
import WorkListModelCount from "./reducers/licenseDetailedListReducer";
import DataSign from "./reducers/dataSignReducer";
import Scrutiny from "./reducers/scrutinyReducer";
import ReassignUserList from "./reducers/pullBackReassignReducer";
import ReassignWorkList from "./reducers/reassignWorkListReducer";
import PaymentList from "./reducers/paymentCategoryListReducer";
import ApprovalDocFlag from "./reducers/approvalDocFlagReducer";
import FileMetaDataDetails from "./reducers/fetchFileMetadataSwsIdReducer";
import GetRemarks from "./reducers/getRemarksReducer";
import GetRemarksFull from "./reducers/getRemarksFullReducer";
import PushRemarks from "./reducers/pushRemarksReducer";
//import Payment from './reducers/paymentReducer';
import DocumentaryCategoryDtls from "./reducers/documentCategoryDtlsReducer";
import IssueDocument from "./reducers/issueDocumentReducers";
import FetchRegistrationNumber from "./reducers/registrationNumberReducer";
import ScrutinyHistory from "./reducers/ScrutinyHistoryReducer";
import FormHistory from "./reducers/FormHistoryReducer";
import IdlsMachineDetails from "./reducers/saveIDLSMachineDetailsReducer";
import GetIdlsMachineDetails from "./reducers/getIDLSMachineDetailsReducers";
import SearchAttribute from "./reducers/searchAttributeReducers";
import GetIdlsMachineHistory from "./reducers/getIDLSMachineHistoryReducers";
import AuditDetails from "./reducers/auditReducer";
// import compareReducer from './reducers/compareReducer';
import reportDetails from "./reducers/reportReducer";
import compilienceworklist from './reducers/CompilienceWorkList'
import GetScrutinyRemark from "./reducers/getScrutinyRemarksReducer";
import PushScrutinyRemark from "./reducers/pushScrutinyRemarksReducer";
import ComplienceworkItems from './reducers/CompenseItemReducer'
import compilienceFilterReducer from "./reducers/compilienceFilterReducer"
export default configureStore({
  reducer: {
    counter: counterReducer,
    // compare: compareReducer,
    workList: WorkList,
    Common: Common,
    workItem: WorkItem,
    appCount: AppCount,
    appLicenseCount: AppLicenseCount,
    chartCount: ChartCount,
    user: User,
    approve: Approve,
    reject: Reject,
    forward: Forward,
    backward: Backward,
    minsIdData: MinsIdData,
    edituser: EditUser,
    pdfData: PdfData,
    getDashboardRepData: GetDashboardRepData,
    deptidData: DeptidData,
    licenseidData: LicenseidData,
    licenseForms: LicenseForms,
    licenseSections: LicenseSections,
    licenseFields: LicenseFields,
    licenseSubFields: LicenseSubFields,
    deptDtlsData: DeptDtlsData,
    getUserdata: GetUserdata,
    updateNsws: UpdateNsws,
    userCreate: UserCreate,
    workflowCreate: WorkflowCreate,
    depmtIdData: DepmtIdData,
    genOtpData: GenOtpData,
    approvalsRequest: ApprovalsRequest,
    noAction: NoAction,
    statusReport: StatusReport,
    validOtpData: ValidOtpData,
    licenseCount: LicenseCount,
    clarification: Clarification,
    workDetail: WorkDetail,
    workListItem: WorkListItem,
    workItemForm: WorkItemForm,
    exportExcelData: ExportExcelData,
    fileDownload: FileDownload,
    logout: Logout,
    bulkFileDownload: BulkFileDownload,
    getNextOfficerName: GetNextOfficerName,
    filterData: filterData,
    // filterUserData: filterUserData,
    // filterDeptData: filterDeptData,
    forgotPassword: ForgotPassword,
    licenses: Licenses,
    licensesList: LicensesList,
    allUsers: AllUsers,
    modulesRoleId: ModulesRoleId,
    modules: Modules,
    allDepartments: AllDepartments,
    captcha: Captcha,
    validateCaptcha: ValidateCaptcha,
    refreshCaptcha: RefreshCaptcha,
    deptWingDtlsData: DeptWingDtlsData,
    roleList: RoleList,
    ministryList: MinistryList,
    usersList: UsersList,
    paymentDetail: PaymentDetail,
    bulkFileIds: BulkFileIds,
    seekInfo: SeekInfo,
    submitInfo: SubmitInfo,
    claimApplication: ClaimApplication,
    reAssignApplication: ReAssignApplication,
    viewWorkflow: ViewWorkflow,
    verificationSchedule: VerificationSchedule,
    editWorkflow: EditWorkFlow,
    workflowUserList: WorkflowUserList,
    notificationDetails: NotificationDetails,
    notificationFlag: NotificationFlag,
    pullLicenseDetails: PullLicenseDetails,
    saveLicenseDetails: SaveLicenseDetails,
    licensePDF: LicensePDF,
    dscToken: DscToken,
    dscCertificate: DscCertificate,
    dscPdf: DscPdf,
    checklistSubmmit: ChecklistSubmmit,
    checklistView: ChecklistView,
    designation: Designation,
    noteSheet: NoteSheet,
    pendingTask: PendingTask,
    workListModelCount: WorkListModelCount,
    dataSign: DataSign,
    scrutiny: Scrutiny,
    reassignUserList: ReassignUserList,
    reassignWorkList: ReassignWorkList,
    // filterSwsIdData: filterSwsIdData,
    // filterAppIdData: filterAppIdData,
    paymentList: PaymentList,
    approvalDocFlag: ApprovalDocFlag,
    fileMetaDataDetails: FileMetaDataDetails,
    getRemarks: GetRemarks,
    getRemarksFull: GetRemarksFull,
    pushRemarks: PushRemarks,
    // payment:Payment,
    documentaryCategoryDtls: DocumentaryCategoryDtls,
    issueDocument: IssueDocument,
    fetchRegistrationNumber: FetchRegistrationNumber,
    formHistory: FormHistory,
    scrutinyHistory: ScrutinyHistory,
    idlsMachineDetails: IdlsMachineDetails,
    getIdlsMachineDetails: GetIdlsMachineDetails,
    searchAttribute: SearchAttribute,
    getIdlsMachineHistory: GetIdlsMachineHistory,
    auditDetail: AuditDetails,
    getScrutinyRemark: GetScrutinyRemark,
    pushScrutinyRemark: PushScrutinyRemark,
    reportData: reportDetails,
    compilienceFilterReducer: compilienceFilterReducer,
    compilienceworklist: compilienceworklist,
    ComplienceworkItems: ComplienceworkItems,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  devTools: process.env.NODE_ENV !== "production",
});
