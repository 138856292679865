import React from "react";
import { Link } from "react-router-dom";

import FontSizeChanger from "react-font-size-changer";

import IndiaLogo from "../../assets/images/india-logo.svg";
import InvestIndiaLogo from "../../assets/images/invest-india-logo.png";
import MAADHYAMLogo from "../../assets/images/maadhyam-logo.svg";
import DPIIT_Logo from "../../assets/images/DPIIT_Logo.png";
import MAADHYAMMain2 from "../../assets/images/maadhyam-main2-logo.png";

import "../Common-style.scss";
import "./header.scss";
import { useSelector } from "react-redux";
import AshokLogo from '../../assets/Path 8103.png'

const Header = (props) => {
  const fontSizer = (
    <FontSizeChanger
      targets={[".fontSizeDisplay"]}
      onChange={(element, newValue, oldValue) => {
        console.log(element, newValue, oldValue);
      }}
      options={{
        stepSize: 0.1,
        range: 3,
      }}
      customButtons={{
        down: (
          <span
            className="mouseHover"
            style={{
              fontSize: "17px",
              display: "flex",
              " flex-direction": "row-reverse",
              marginTop: "-2px",
              "font-weight": "300",
              "font-family": "roboto condensed",
            }}
          >
            A<sup>-</sup>
          </span>
        ),
        up: (
          <span
            className="mouseHover"
            style={{
              fontSize: "17px",
              display: "flex",
              " flex-direction": "row-reverse",
              marginTop: "-2px",
              "font-weight": "300",
              "font-family": "roboto condensed",
            }}
          >
            A<sup>+</sup>
          </span>
        ),
        style: {
          backgroundColor: "inherit",
          borderStyle: "none",
          color: "#898888",
          color: "#223856 !important",
          
          // fontWeight:"300 !important",
          width: "15px",

          paddingTop: "2px",
        },
      }}
    />
  );

  const userProfile = useSelector((state) => state.user.userProfile);
  const { ministryDesc } = userProfile && userProfile;

  console.log("Image URL:" + userProfile.imageURL);

  return (
    <>
    {userProfile && userProfile.msg === "Unauthorized User" ? null  :
      <div className="header-top-section">
        <div className="content-wrapper">
          <div className="inner-content">
            <div className="left-side">
              <div className="image-box">
                <a href="https://www.nsws.gov.in/" target="_blank">
                  {/* <img src={MAADHYAMMain2} alt="Maadhyam" /> */}
                  <img src={AshokLogo} alt="Ashoka-Logo" />
                </a>
              </div>
              {/* <div>|</div> */}
              <div className="ministry-desc">{ministryDesc}</div>
            </div>
            <div className="right-side">
              <ul className="navbar-menu">
                <li>
                  <a
                    href="https://www.nsws.gov.in/contact-us"
                    target="_blank"
                  >
                    Contact&nbsp;Us
                  </a>
                  {/* <Link to={`/contact`} target="_blank">Contact</Link> */}
                </li>
                {/* <li>
                  <Link to={`/FAQ`} target="_blank">
                    FAQ
                  </Link>
                </li> */}
                                <li>
                   <a
                    href="https://www.nsws.gov.in/faqs"
                    target="_blank"
                  >
                    FAQ&nbsp;
                  </a>
                </li>
                &nbsp;&nbsp;&nbsp;
                <li>
                  <div className="resize">{fontSizer}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default Header;
