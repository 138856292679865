import React from "react";

import PhoneIcon from '../assets/images/call-icon.svg';
import EmailIcon from '../assets/images/mail-icon1.svg';
import ChatIcon from '../assets/images/chat-icon.svg';

export const ChatSvgIcon = <>
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 63.256 61.665">
        <g transform="translate(0 0)">
                <path d="M5.22,45.358a30.839,30.839,0,1,1,7.95,9.561L0,58.5Zm30.46-5.119a10.174,10.174,0,0,1,.5-4.107A10.852,10.852,0,0,1,39,33.095a29.572,29.572,0,0,0,5.734-5.769,8.246,8.246,0,0,0,1.34-4.49,9.747,9.747,0,0,0-3.655-7.51Q38.761,12.1,32.574,12.1a13.805,13.805,0,0,0-9.494,3.194,10.96,10.96,0,0,0-3.9,7.771l6.579.836a8.333,8.333,0,0,1,2.532-4.76,6.892,6.892,0,0,1,4.587-1.557,6.543,6.543,0,0,1,4.516,1.488,4.573,4.573,0,0,1,1.679,3.559,4.456,4.456,0,0,1-.948,2.759,30.715,30.715,0,0,1-3.759,3.341,15.89,15.89,0,0,0-4.186,4.595,11.3,11.3,0,0,0-1.053,5.2c0,.209.009.775.026,1.714Zm-3.263,9.329a3.3,3.3,0,1,0-3.3-3.3,3.3,3.3,0,0,0,3.3,3.3" transform="translate(0 0)" fill="#fff" fillRule="evenodd" />
        </g>
</svg>
</>

export const PhoneImgIcon = PhoneIcon;
export const EmailImgIcon = EmailIcon;
export const ChatImgIcon = ChatIcon;
