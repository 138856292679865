import React, { useEffect, useState, useRef } from "react";
import { Tooltip } from "antd";
import useOutsideClick from "../ClickOutside"
import './contact-widget.scss';
import { ChatSvgIcon, PhoneImgIcon, EmailImgIcon, ChatImgIcon } from "../../utils/SVGS";

const ContactWidget = () => {
    const [openAction, setOpenAction] = useState(false);
    const ref = useRef();

    useEffect(() => {
        window?.FreshworksWidget('hide', 'launcher');
    }, []);

    useOutsideClick(ref, () => {
        if (openAction) {

            // setOpenAction(false);
        }
    });

    const openWidget = () => {
        window?.FreshworksWidget('open');
        setOpenAction(true);
    };

    const openContactActions = () => {


        if (openAction)
            setOpenAction(false);
        else
            setOpenAction(true);
    };

    return (
        <div className="contact-widget-container">
            <Tooltip title="Contact us" placement="left">
                <div className={openAction ? "contact-action-btn active" : "contact-action-btn"} onClick={openContactActions}>
                    <i className="chat-icon">
                        {ChatSvgIcon}
                    </i>
                </div>
            </Tooltip>

            <ul className="contact-actions">
                <Tooltip title="Contact Form" placement="left">
                    <li>
                        <button onClick={openWidget}>
                            <img src={ChatImgIcon} alt="chat-icon" width="20" height="auto" />
                        </button>
                    </li>
                </Tooltip>

                <Tooltip title="Call us at 1800 102 5841 [ Monday - Saturday, 9am - 6pm ]" placement="left">
                    <li>
                        <a href="tel:18001025841" ref={ref}>
                            <img src={PhoneImgIcon} alt="call-icon" width="20" height="auto" />
                        </a>
                    </li>
                </Tooltip>

                <Tooltip title="Write to us at : contactus-nsws[at]investindia[dot]org[dot]in" placement="left">
                    <li ref={ref}>
                        <a>
                            <img src={EmailImgIcon} alt="email-icon" width="20" height="auto" />
                        </a>
                    </li>
                </Tooltip>
            </ul>
        </div>
    )
};

export default ContactWidget;