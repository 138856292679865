import types from '../types';

const initialState = {
  jwtAccessToken: '',
  noteSheet: false,
};

const noteSheet = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState
          };
      case types.SET_NOTSHEET_DATA:
        const { noteSheet} = action.payload;
        return {
          ...state,
          noteSheet,
        };
    default:
      return state;
  }
};

export default noteSheet;