import { fetchData } from './API';
import types from '../types';
import URI from '../../URIConstant';
// import axios from 'axios';

export const ForgotpasswordAction = (username, captchaId, captchaAnswer, onSuccess, onError) => dispatch => {
    console.log("**GET userdata**" + username);
    dispatch(
        fetchData({
            url: URI+`/nsws_user_service/user/forgotpassword`,
            method: 'POST',
            data: {
                userId: username,
                username: username,
                captchaId : captchaId,
                captchaAnswer : captchaAnswer
            },
            addJwtFromRedux: false,
            addUserName: false,
            addSrutiny: false,
            label: true,
            onSuccess: data => dispatch =>
            {
                console.log("**GET userdata**" + username);
                onSuccess(data);
                dispatch({
                    type: types.SET_FORGOTPASSWORD_DATA,
                    payload: {
                        forgotPassword: data,
                    },
                },
               )
            },
                
            onFailure: (data) => onError && onError(data),
        }),
    );
};