import types from '../types';

const initialState = {
    jwtAccessToken: '',
    getRemarksFull: false,
};

const getRemarksFull = (state = initialState, action) => {

    switch (action.type) {
        case types.SET_USER_JWT:
            return {
                ...state,
                jwtAccessToken: action.payload,
            };
        case types.RESET_CONFIGURATOR_STATE:
            return {
                ...initialState
            };
        case types.GET_REMARK_DRAFT_FULL:

            const { getRemarksFull } = action.payload;
            return {
                ...state,
                getRemarksFull,
            };
        default:
            return state;
    }
};


export default getRemarksFull;