import React, { useState, lazy, useEffect } from "react";
import { isEmptyObject } from "jquery";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { useSelector } from "react-redux";
import sidebarBg from "../../assets/bg1.jpg";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
// import "./index.scss";
import "../Common-style.scss";

const DashboardSidebar = lazy(() =>
  import(/* webpackChunkName: "DashboardSidebar" */ "./DashboardSidebar")
);

const ApplicationSidebar = lazy(() =>
  import(/* webpackChunkName: "ApplicationSidebar" */ "./ApplicationSidebar")
);

const WorkItemSidebar = lazy(() =>
  import(/* webpackChunkName: "WorkItemSidebar" */ "./WorkItemSidebar")
);

const Sidebar = (props) => {
  
  const userProfile = useSelector((state) => state.user.userProfile);
  const isApiLoading = useSelector((state) => state.Common.isApiLoading);
  const [pathName, setPathName]= useState(useLocation());
  
  let sidebarContent ;
  const [unauthorized, setUnauthorized] = useState("");
  useEffect(() => {
    if(userProfile && userProfile.msg==="Unauthorized User"){
      setUnauthorized(true)
    }
    else if(userProfile && userProfile.msg==="Authorized User"){
      setUnauthorized(false)
    }
  },[userProfile])
  useEffect(() => {
    setPathName(window.location.pathname);
  }, [pathName]);

  return (
    <>
    {unauthorized? null:
     (unauthorized===false ? <ProSidebar image={props.showBackground ? false : false}>
        <SidebarHeader>
          <div
            style={{
              padding: "24px",
              // textTransform: "uppercase",
              // fontWeight: "bold",
              // fontSize: 15,
              letterSpacing: "0.5px",
              overflow: "hidden",
              color: "rgb(255, 255, 255)",
              background: "#055ced",              
            }}
          >
          {/* <div className="sidebarname">{fullName}</div>  
          <div className="sidebarministry">{deptDesc}</div>  */}
          </div>
        </SidebarHeader>
        <Menu iconShape="square" className="dashboardd proMenuItem">
           <DashboardSidebar />
        </Menu>
      </ProSidebar>:null)}
    </>
  );
      
};

export default Sidebar;