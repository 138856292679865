import Keycloak from "keycloak-js";

const getConfigOnEnv = (env) =>{
  switch (env){
    case "https://qa-nsws.investindia.gov.in":return {
      "realm": "madhyam",
      "url": "https://qa-nsws.investindia.gov.in/auth/",
      "clientId": "portal-qa",
    }
    case "https://uat-nsws.investindia.gov.in":return {
      "realm": "madhyam",
      "url": "https://dev-nsws.investindia.gov.in/auth/",
      "clientId": "portal",
    }
    case "https://qa-nsws-mnstrportal.investindia.gov.in":return {
      "realm": "madhyam",
      "url": "https://qa-nsws.investindia.gov.in/auth/",
      "clientId": "ministry-portal-qa",
    }
    case "http://192.168.50.208:8888":return {
      "realm": "madhyam",
      "url": "https://dev-nsws.investindia.gov.in/auth/",
      "clientId": "ministry-portal-dev",
    }
    case "https://uat-nsws-mnstrportal.investindia.gov.in":return {
      "realm": "madhyam",
      "url": "https://sso-uat-nsws.investindia.gov.in/auth/",
      "clientId": "ministry-portal-uat",
    }
    default:return {
      "realm": "madhyam",
       "url": "https://www.nsws.gov.in/auth/",
      //"url": "https://www.ppe.nsws.gov.in/auth/",
      "clientId": "ministry-portal-prod",
    }
  }
}

const config=getConfigOnEnv(window.location.origin);

const _kc = new Keycloak(config);


/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  const env = window.location.origin;
  console.log(env)
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    //env.includes("localhost")? window.location.origin + '/silent-check-sso.html':   window.location.origin + '/portal/static/html/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
     //  if (authenticated) {
      onAuthenticatedCallback();
    //doLogin();
    //   } 
       //else {
      //   doLogout();
      // }
    })
};

const doLogin = _kc.login;

const doLogout = () =>{
  _kc.logout({redirectUri:  `${window.location.origin}/`});
  //this.props.history.push('/');
}



const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(1)
    .then(successCallback)
    .catch(doLogout);

const getUsername = () => _kc.tokenParsed?.preferred_username ? _kc.tokenParsed?.preferred_username : '-';

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
};

export default UserService;