import { fetchData } from './API';
import types from '../types';
import URI from '../../URIConstant';


export const getCaptchaCode = (onSuccess, onError) => dispatch => {
    dispatch(
        fetchData({
            url: URI + `/nsws_user_service/api/captchaImg`,
            method: 'POST',
            data: {
                
              },
            addJwtFromRedux: false,
            addUserName: false,
            addSrutiny: false,
            label: true,
            onSuccess: data => dispatch =>
            {
                onSuccess(data);
                dispatch({
                    type: types.SET_CAPTCHA_CODE,
                    payload: {
                        captcha: data,
                    },
                },
               )
            },
            onFailure: () => onError && onError(),
         }),
    );
};