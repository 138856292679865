import { fetchData } from './API';
import types from '../types';
import URI from '../../URIConstant';


export const validateCaptchaCode = (captchaId,captchaAnswer,onSuccess, onError) => dispatch => {
    dispatch(
        fetchData({
            //url: URI+`/nsws_license_worklist/worklist/backward`,
            url: URI + `/nsws_user_service/api/validateCaptcha`,
            // url:'http://3.7.220.235:8025/api/validateCaptcha',
            method: 'POST',
            data: {
                "captchaId" : captchaId,
                "captchaAnswer" : captchaAnswer 
              },
            addJwtFromRedux: false,
            addUserName: false,
            addSrutiny: false,
            label: true,
            onSuccess: data => dispatch =>
            {
                onSuccess(data);
                dispatch({
                    type: types.VALIDATE_CAPTCHA_CODE,
                    payload: {
                        validateCaptcha: data,
                    },
                },
               )
            },
            onFailure: () => onError && onError(),
         }),
    );
};