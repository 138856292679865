import types from '../types';

const initialState = {
  jwtAccessToken: '',
  pushRemarks: false,
};

const pushRemarks = (state = initialState, action) => {

  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState
          };
      case types.PUSH_REMARK_DRAFT:       
        const { pushRemarks} = action.payload;
        return {
          ...state,
          pushRemarks,
        };
    default:
      return state;
  }
};


export default pushRemarks;