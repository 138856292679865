// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=PT+Serif&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,300;1,400;1,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Roboto Condensed', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale; }\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Roboto Condensed', sans-serif; }\n\n.header-img {\n  position: relative;\n  width: 100%; }\n", "",{"version":3,"sources":["webpack://src/index.scss"],"names":[],"mappings":"AAIA;EACE,SAAS;EACT,8EAA8E;EAC9E,mCAAmC;EACnC,kCAAkC,EAAA;;AAIpC;EACE,qFAAqF,EAAA;;AAGvF;EACE,kBAAkB;EAClB,WAAW,EAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,300;1,400;1,700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Roboto Condensed', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Roboto Condensed', sans-serif;\n}\n\n.header-img {\n  position: relative;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
