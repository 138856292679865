import types from '../types';

const initialState = {
  jwtAccessToken: '',
  appCount: false,
  dashboard: {},
  department:[],
  wings:[],
  barchartDetails:[],
  cardDetails:[],
  doughnutChartDetails:[],
  licenseWiseDetails:[],
  drilledDownDetails:[],
  exportData:[]
};

const appCounts = (state = initialState, action) => {
 
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState
          };
      case types.SET_APP_COUNT:
        const { appCounts} = action.payload;
        return {
          ...state,
          appCounts,
        };
        case types.SET_DASHBOARD_DATA: {
          const { dashboard, filter } = action.payload;
          if(filter === 'barChart'){
            let barchartDetails = dashboard.barchartDetails;
            return {
              ...state,
              barchartDetails
            }
          } else if(filter === 'doughnut'){
            let doughnutChartDetails = dashboard.doughnutChartDetails;
            return {
              ...state,
              doughnutChartDetails
            }
          } else if (filter === 'drilledDown') {
            let drilledDownDetails = dashboard.drilledDownDetails;
            return {
              ...state,
              drilledDownDetails
            }
          } else {
          let barchartDetails = dashboard.barchartDetails;
          let cardDetails =  dashboard.cardDetails;
          let doughnutChartDetails =  dashboard.doughnutChartDetails;
          let licenseWiseDetails =  dashboard.licenseWiseDetails 
          let drilledDownDetails =  dashboard.drilledDownDetails;

          return {
            ...state,
            dashboard,
            barchartDetails,
            cardDetails,
            doughnutChartDetails,
            licenseWiseDetails,
            drilledDownDetails,
          };
        }
        }
          case types.SET_DASHBOARD_DEPARTMENT:
            const { department } = action.payload;
            return {
              ...state,
              department
            };
            case types.SET_DASHBOARD_WINGS:
            const { wings } = action.payload;
            return {
              ...state,
              wings
            };
            case types.SET_DASHBOARD_EXPORT_DATA:
              const {exportData} = action.payload;
               return {
                ...state,
                exportData
              }
    default:
      return state;
  }
};

export default appCounts;