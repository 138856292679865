import types from '../types';

const initialState = {
    jwtAccessToken: '',
    verificationSchedule: false,
};

const verificationSchedule = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_JWT:
            return {
                ...state,
                jwtAccessToken: action.payload,
            };
        case types.RESET_CONFIGURATOR_STATE:
            return {
                ...initialState,
            };
        case types.SET_VERIFICATION_SCHEDULE:
            const { verificationSchedule } = action.payload;
            return {
                ...state,
                verificationSchedule,
            };
        default:
            return state;
    }
};

export default verificationSchedule;