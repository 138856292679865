import types from '../types';

const initialState = {
  jwtAccessToken: '',
  getRemarks: false,
};

const getRemarks = (state = initialState, action) => {

  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState
          };
      case types.GET_REMARK_DRAFT:
       
        const { getRemarks} = action.payload;
        return {
          ...state,
          getRemarks,
        };
    default:
      return state;
  }
};


export default getRemarks;