import types from "../types";

const initialState = {
  errorModal: false,
  successModal: false,
  confirmationModal: false,
  infoModal: false,
  isApiLoading: false,
  showScrutiny: false,
  SEEK_INFO: false,
  Send_Back: false,
  Raise_Clarification: false,
  suspension: false,
  cancellation: false,
  investorBtngroup: 0,
  postScrutiny: false,
  updateRemarkState: "",
  updateContentId: "",
  closeSeekInfoFun: false,
  closeBackFun: false,
  individualScrutiny: "",
  Additional_Info: "",
  statusArray: [],
  applicationNoLst: [],
  licenseArray: [],
  usersArray: [],
  receiversArray: [],
  deptArray : [],
  deptWngArray :[],
  startDate: "",
  endDate: "",
  labelsArray: [],
  showbtn: true,
  mandatoryList: [],
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case types.API_START:
      if (action.payload !== types.HIDE_APPLICATION_LOADER) {
        return {
          ...state,
          isApiLoading: true,
        };
      }
      break;
    case types.API_END:
      if (action.payload !== types.HIDE_APPLICATION_LOADER) {
        return {
          ...state,
          isApiLoading: false,
        };
      }
      break;
    case types.RESET_CONFIGURATOR_STATE:
      return {
        ...initialState,
      };

    case types.ACCESS_DENIED:
      return {
        ...state,
        errorModal: "ERR:100401 - Sorry. Please try again",
      };
    case types.SHOW_SCURITINY:
      return {
        ...state,
        showScrutiny: action.payload,
      };
    case types.SEEK_INFO:
      return {
        ...state,
        SEEK_INFO: action.payload,
      };
    case types.SEND_BACK:
      return {
        ...state,
        Send_Back: action.payload,
      };
      case types.SUSPENSION:
        return {
          ...state,
          suspension: action.payload,
        };
        case types.CANCELLATION:
          return {
            ...state,
            cancellation: action.payload,
          };
    case types.RAISE_CLARIFICATION:
      return {
        ...state,
        Raise_Clarification: action.payload,
      };
    case types.INVESTOR_GRP_BTN:
      return {
        ...state,
        investorBtngroup: action.payload,
      };
    case types.POSTSCRUTINY:
      return {
        ...state,
        postScrutiny: action.payload,
      };
    case types.UPDATEREMARKSTATE:
      return {
        ...state,
        updateRemarkState: action.payload,
      };
    case types.UPDATE_CONTENT_ID:
      return {
        ...state,
        updateContentId: action.payload,
      };
    case types.CLOSESEEKINFO:
      return {
        ...state,
        closeSeekInfoFun: action.payload,
      };
    case types.CLOSE_BACK_FUN:
      return {
        ...state,
        closeBackFun: action.payload,
      };
    case types.INVIDUAL_SCRUTINY:
      return {
        ...state,
        individualScrutiny: action.payload,
      };
    case types.ADDITIONAL_INFO:
      return {
        ...state,
        Additional_Info: action.payload,
      };
    case types.LISENCE_ARRAY:
      return {
        ...state,
        licenseArray: action.payload,
      };
    case types.STATUS_ARRAY:
      return {
        ...state,
        statusArray: action.payload,
      };
    case types.DEPT_ARRAY:
      return{
        ...state,
        deptArray : action.payload,
      };
      case types.DEPT_WNG_ARRAY:
        return{
          ...state,
          deptWngArray : action.payload,
        };
    case types.LABELS_ARRAY:
      return {
        ...state,
        labelsArray: action.payload,
      };
    case types.RECEIVED_ARRAY:
      return {
        ...state,
        receiversArray: action.payload,
      };
    case types.USERS_ARRAY:
      return {
        ...state,
        usersArray: action.payload,
      };
    case types.APPLICATIONNUM_ARRAY:
      return {
        ...state,
        applicationNoLst: action.payload,
      };
    case types.STARTDATE_ARRAY:
      console.log(action.payload,"STARTDATE_ARRAY")
      return {
        ...state,
        startDate: action.payload,
      };
    case types.ENDDATE_ARRAY:
      return {
        ...state,
        endDate: action.payload,
      };
      case types.SHOW_BTN:
        return{
          ...state,
          showbtn:action.payload
        }
      case types.MANDATORYLIST:
      return {
        ...state,
        mandatoryList:action.payload
        // mandatoryList: action.payload
      }
    default:
      return state;
  }
};

export default common;
