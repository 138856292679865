import types from '../types';

const initialState = {
  jwtAccessToken: '',
  forward: false,
};

const forward = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState ,
          };
      case types.SET_FORWARD_DATA:
        const { forward} = action.payload;
        return {
          ...state,
          forward,
        };
    default:
      return state;
  }
};

export default forward;
