import types from '../types';

const initialState = {
    jwtAccessToken: '',
    userProfile: {},
    isAuthenticated: false,
    isUserUpdated: false,
};
const users = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_JWT:
          return {
            ...state,
            jwtAccessToken: action.payload,
            isAuthenticated: true,
          };
          case types.RESET_CONFIGURATOR_STATE:
            return { 
               ...initialState,
              };
    case types.SET_USER_DATA_UNAUTH:
      return { 
        ...initialState,
        userProfile:{msg:"Unauthorized User"}
       };
       case types.SET_USER_DATA_DEACTIVATED:
        return { 
          ...initialState,
          userProfile:{msg:"User Is Deactivated"}
         };
       
    case types.SET_USER_DATA:
        const { users} = action.payload;
        const { userProfileSubSet } = users;
        console.log(`userdata`, userProfileSubSet);
        let totalData = {...userProfileSubSet}
        totalData.msg = "Authorized User"
      //console.log("111 users inside reducer "+users.name+" users inside reducer state "+JSON.stringify(a));
      return {
        ...state,
        userProfile: totalData,
        isUserUpdated:true,
      };
    default:
      return state;
  }
};
export default users;
