import axios from "axios";
import types from "../types";
import { accessDenied, apiError, apiStart, apiEnd, apiErrorLogout } from "../action/API";
import get from "lodash.get";
import store from "../store";
import UserService from "../services/UserService";

const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type !== types.API) return;

  const {
    url,
    method,
    data,
    accessToken,
    onSuccess,
    onFailure,
    label,
    headers,
    isBackgroundCall,
    addJwtFromRedux,
    addUserName,
    addSrutiny,
    paymentApiCall,
    removeappidHeader,
    responseType
  } = action.payload;
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
  const reduxStore = store.getState();
  const jwtToken = get(reduxStore, "user.jwtAccessToken");
  // const users = get(reduxStore, "user.userDetails");

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  if (removeappidHeader) {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["app_id"];
    delete axios.defaults.headers.common["userName"];
  }
  // if (!removeappidHeader) axios.defaults.headers.common['app_id'] = getAppId();
  if (!removeappidHeader) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${addJwtFromRedux ? jwtToken : accessToken
      }`;
  }

  if (addUserName && paymentApiCall === false) {
    //console.log("Add username "+addUserName);
    axios.defaults.headers.common["userName"] = UserService.getUsername();
    axios.defaults.headers.common["Content-Security-Policy"] = "self";
    axios.defaults.headers.common["X-Frame-Options"] = "SAMEORIGIN";
  } else {
    delete axios.defaults.headers.common["userName"];
    delete axios.defaults.headers.common["Content-Security-Policy"];
    delete axios.defaults.headers.common["X-Frame-Options"];
  }

  if (addSrutiny) {
    axios.defaults.headers.common["access_id"] = "MIN_TEST_0";
    axios.defaults.headers.common["access_secret"] = "MintesT@1234";
    axios.defaults.headers.common["api-key"] = "Min1@PLS07";
  }
  else {
    delete axios.defaults.headers.common["access_id"];
    delete axios.defaults.headers.common["access_secret"];
    delete axios.defaults.headers.common["api-key"];
  }

  if (label) {
    dispatch(apiStart(label));
  }

  axios.interceptors.request.use((config) => {
    try {

      if (UserService.isLoggedIn()) {
        const cb = () => {
          config.headers.Authorization = `Bearer ${addJwtFromRedux ? jwtToken : UserService.getToken()
            }`;
          return Promise.resolve(config);
        };
        return UserService.updateToken(cb);
      }
    }
    catch (e) {
      console.log("INSIDE CATCH MIDDLEWARE " + e);
    }
  });

  axios
    .request({
      url,
      method,
      headers,
      [dataOrParams]: data,
      responseType,
    })
    .then(({ data }) => {
      onSuccess && dispatch(onSuccess(data));
    })
    .catch((error) => {
      dispatch(
        apiError(error.message, {
          url,
          method,
          headers,
          [dataOrParams]: data,
          isBackgroundCall,
        })
      );
      onFailure && onFailure(error);
      console.info("error ", error);
      if (error.response && error.response.status === 401 && error.response.data === "UnAuthorized Access due to logged in another System or JWT token Expired") {
        dispatch(accessDenied(window.location.pathname));
        dispatch(apiErrorLogout());
      }
      else if (error.response && error.response.status === 401 && error.response.data === "UnAuthorized Access") {
        console.info("error response inside", error.response)
        window.location.pathname = "/logout";
        dispatch(accessDenied(window.location.pathname));
      }
      else if (error.response && error.response.status === 403) {
        dispatch(accessDenied(window.location.pathname));
      }
    })
    .then(() => {
      if (label) {
        setTimeout(() => {
          dispatch(apiEnd(label));
        }, 500);
      }
    });
};

const middelware = [apiMiddleware];
export default middelware;