import types from '../types';

const initialState = {
  jwtAccessToken: '',
  filterData: {
    dateData: {
      "startDate": "",
      "endDate": "",
    },
    licenseDesc: [],
    userData: [],
    deptData: [],
    swsIdData: [],
    appIdData: [],
    statusData: [],
    recordsPerPage: 10,
    firstRecFiltrCount: 0,
    lastRecFiltrCount: 0,
    currenPage: 1,
    nexPage: 1,
    appliCountRedux: 0,
    workListCountRedux: 0,
    filterStatus:false,
    allApprovalLicenseStatus:false,
    allApplicationStatus:false
  }
};

export const filterData = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
    case types.RESET_CONFIGURATOR_STATE:
      return {
        ...initialState,
      };
    case types.SET_FILTER_DATA:
      const { filterData } = action.payload;
      return {
        ...state,
        filterData,
      };
    case types.RESET_FILTER_DATA:
      return {
        ...initialState,
      };
    case "RESET_CHECK_FILTER_DATA":
      return {
        ...state,
        filterData: {
          ...state.filterData,
          appIdData: [],
          statusData: [],
          swsIdData: [],
          userData: [],
          licenseDesc: [],
          dateData:{
            startDate:"",
            endDate:""
          },
          filterStatus:false,
        }
      }
    default:
      return state;
  }
};

// export const filterUserData = (state = initialState, action) => {
//   switch (action.type) {
//     case types.SET_USER_JWT:
//       return {
//         ...state,
//         jwtAccessToken: action.payload,
//       };
//       case types.RESET_CONFIGURATOR_STATE:
//         return { 
//            ...initialState ,
//           };
//       case types.SET_FILTER_USERS:
//         const { filterUserData} = action.payload;
//         return {
//           ...state,
//           filterUserData,
//         };
//     default:
//       return state;
//   }
// };

// export const filterDeptData = (state = initialState, action) => {
//   switch (action.type) {
//     case types.SET_USER_JWT:
//       return {
//         ...state,
//         jwtAccessToken: action.payload,
//       };
//       case types.RESET_CONFIGURATOR_STATE:
//         return { 
//            ...initialState ,
//           };
//       case types.SET_FILTER_DEPARTMENTS:
//         const { filterDeptData} = action.payload;
//         return {
//           ...state,
//           filterDeptData,
//         };
//     default:
//       return state;
//   }
// };

// export const filterSwsIdData = (state = initialState, action) => {
//   switch (action.type) {
//     case types.SET_USER_JWT:
//       return {
//         ...state,
//         jwtAccessToken: action.payload,
//       };
//       case types.RESET_CONFIGURATOR_STATE:
//         return { 
//            ...initialState ,
//           };
//       case types.SET_FILTER_SWSID:
//         const { filterSwsIdData} = action.payload;
//         return {
//           ...state,
//           filterSwsIdData,
//         };
//     default:
//       return state;
//   }
// };

// export const filterAppIdData = (state = initialState, action) => {
//   switch (action.type) {
//     case types.SET_USER_JWT:
//       return {
//         ...state,
//         jwtAccessToken: action.payload,
//       };
//       case types.RESET_CONFIGURATOR_STATE:
//         return { 
//            ...initialState ,
//           };
//       case types.SET_FILTER_APPID:
//         const { filterAppIdData} = action.payload;
//         return {
//           ...state,
//           filterAppIdData,
//         };
//     default:
//       return state;
//   }
// };