import React from 'react';
import "../Common-style.scss";
import fastSimple from '../../assets/images/LoginLogos/ic-fast-simple.svg';
import folder from '../../assets/images/LoginLogos/ic-folder.svg';
import computer from '../../assets/images/LoginLogos/ic-computers.svg';
import usersWh from '../../assets/images/LoginLogos/ic-users-white.svg';
import badgeImg from '../../assets/images/LoginLogos/ic-badge-whites.svg';


const LoginContent = props => {
    return (
        <div className="login-content-wrapper">
            {/* <div className="main-heading">Begin your business</div>
            <div className="main-heading">journey in India.</div> */}
            <div className="feat-ul-list">
                {/* <p className="text-desc loginpage2">Take our Investor KYA and get all the required approvals/licenses for your business.</p> */}

                <ul>
                    <li className="feat-list">
                        {/* <div className="icon"><img src={fastSimple} alt="Icon" width="20" height="28" /> </div>
                        <span className="feat-text">Simple, fast, and efficient</span> */}
                    </li>
                    <li className="feat-list">
                        {/* <div className="icon"><img src={folder} alt="Icon" width="20" height="28" /></div>
                        <span className="feat-text">All in one platform</span> */}
                    </li>
                    <li className="feat-list">
                        {/* <div className="icon"><img src={computer} alt="Icon" width="20" height="28" /></div>
                        <span className="feat-text">Completely online, exceptional user experience</span> */}
                    </li>

                </ul>
            </div>
            <br />
            <div>
                <ul>
                    <li>
                        {/* <img src={usersWh} alt="Icon" width="20" height="28" className="loginpage3" />&nbsp;&nbsp;&nbsp;
                        <span className="footer-text"><div>34,500</div><div>Registered Investors</div></span> */}
                    </li>&nbsp;&nbsp;&nbsp;

                    <li>
                        {/* <img src={badgeImg} alt="Icon" width="20" height="28" className="loginpage3" />&nbsp;&nbsp;&nbsp;
                        <span className="footer-text"><div>4,500</div><div>Approvals Issued</div></span> */}
                    </li>
                </ul>
            </div>

        </div>

    )
}

export default LoginContent;