import types from '../types';

const initialState = {
    jwtAccessToken: '',
    moduleRoleId: "",
};

const modulesRoleId = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_JWT:
            return {
                ...state,
                jwtAccessToken: action.payload,
            };
        case types.RESET_CONFIGURATOR_STATE:
            return {
                ...initialState,
            };
        case "SET_MODULEROLEID_DATA":
            const { moduleRoleId } = action.payload;
            return {
                ...state,
                moduleRoleId,
            };
        default:
            return state;
    }
};

export default modulesRoleId;